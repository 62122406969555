


























































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldAddress from '@/components/FieldAddress.vue';
import FieldDate from '@/components/FieldDate.vue';
import USER from '@/graphql/queries/User.graphql';
import USER_UPDATE from '@/graphql/mutations/UserUpdate.graphql';
import { required } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { User, UserUpdateMutationVariables } from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'ProfileEdit',
  components: {
    AppMain,
    AppForm,
    FieldAddress,
    FieldDate,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: '',
          notes: '',
        },
        haulerLicense: '',
        haulerExpiration: '',
        driversLicense: '',
        driversExpiration: '',
      },
      user: {} as User,
    };
  },
  computed: {
    hasUser(): boolean {
      return this.user && !!this.user.id;
    },
  },
  validations() {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        email: { required },
        address: {
          street: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
      },
    };
  },
  methods: {
    validationMessages,
    fillForm(user: User) {
      if (!user) return;
      this.form = {
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        password: '',
        phone: user.phone || '',
        address: {
          street: user.address ? user.address.street : '',
          city: user.address ? user.address.city : '',
          state: user.address ? user.address.state : '',
          zip: user.address ? user.address.zip : '',
          notes: user.address ? user.address.notes || '' : '',
        },
        haulerLicense: user.haulerLicense || '',
        haulerExpiration: user.haulerExpiration || '',
        driversLicense: user.driversLicense || '',
        driversExpiration: user.driversExpiration || '',
      };
    },
    async saveUser() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: USER_UPDATE,
          variables: {
            input: {
              user: {
                firstName: this.form.firstName,
                lastName: this.form.lastName,
                email: this.form.email,
                password: this.form.password,
                phone: this.form.phone,
                haulerLicense: this.form.haulerLicense,
                haulerExpiration: this.form.haulerExpiration,
                driversLicense: this.form.driversLicense,
                driversExpiration: this.form.driversExpiration,
              },
              address: {
                street: this.form.address.street,
                city: this.form.address.city,
                state: this.form.address.state,
                zip: this.form.address.zip,
                notes: this.form.address.notes,
              },
            },
          } as UserUpdateMutationVariables,
        });
        this.$notify({ text: 'Profile updated!', type: 'success' });
        this.$router.replace({ name: 'profile' });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
  apollo: {
    user: {
      query: USER,
      result({ data }: ApolloQueryResult<{ user: User }>) {
        if (data) this.fillForm(data.user);
      },
    },
  },
});
